import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IQuoteDetail } from './quote.interface';

type QuoteDetailResponse = {
  quote: IQuoteDetail;
};

export const buildGetQuote = appQueryFactory<string, IQuoteDetail>({
  query: (id) => ({
    url: EndpointUrlBuilder.getQuote(id),
    method: 'GET',
  }),
  transformResponse(response: QuoteDetailResponse) {
    return response.quote;
  },
});
