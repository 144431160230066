import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IAppointmentSummary } from '../appointment-summary.interface';
import { ApiModel } from './form-config';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { validateFilterAppointmentSummary } from './spec/validate-appointment-summaries.spec';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type AppointmentSummaryResponse = IPaginable<IAppointmentSummary>;
export const buildGetAppointmentSummaries = appQueryFactory<QueryParams, AppointmentSummaryResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getAppointmentSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: AppointmentSummaryResponse) {
    validateFilterAppointmentSummary(response, EndpointUrlBuilder.getAppointmentSummaries());
    validatePagination(response, EndpointUrlBuilder.getAppointmentSummaries());
    return response;
  },
});
