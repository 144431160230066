import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IEmailTemplate } from '@features/customer/email-templates/email.template.interface';

interface IEmailTemplatesResponse {
  emailTemplates: IEmailTemplate[];
}

export const buildGetEmailTemplates = appQueryFactory<number, IEmailTemplate[]>({
  query: (customerId) => ({
    url: EndpointUrlBuilder.getEmailTemplates(),
    method: 'GET',
    params: { id: customerId },
  }),
  transformResponse: (response: IEmailTemplatesResponse): IEmailTemplate[] => response.emailTemplates,
});
