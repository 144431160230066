import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ToSnakeCase } from '@utils/model-to-snake-case';
import { validateActionRecords } from '@features/action-records/spec/validate-action-record.spec';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { GetModelProgressRecordsQueryParams } from '@features/action-records/api';
import { RecordModelNames } from '@features/action-records/record-model-name.type';
import { IPaginable } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';

export const buildGetOrderContactRecords = appQueryFactory<
  GetModelProgressRecordsQueryParams,
  IPaginable<IActionRecord>
>({
  query: ({ modelName, id }) => ({
    url: EndpointUrlBuilder.getModelContactRecord(modelName, id),
    method: 'GET',
  }),
  transformResponse(response: IPaginable<IActionRecord>, meta, request) {
    const { modelName, id } = request;
    validateActionRecords(response.entries, EndpointUrlBuilder.getModelContactRecord(modelName, id));
    validatePagination(response, EndpointUrlBuilder.getModelContactRecord(modelName, id));
    return response;
  },
});

export interface IProgressRecordRequest {
  modelName: typeof RecordModelNames.Order;
  id: number;
  request: ToSnakeCase<Pick<IActionRecord, 'way' | 'recordType' | 'details'>>;
}

type ICreateOrderContactRecordResponse =
  | {
      contactRecord: IActionRecord;
    }
  | IErrorResponse;

export const buildCreateOrderContactRecord = appMutationFactory<IProgressRecordRequest, IActionRecord | IErrorResponse>(
  {
    query: ({ modelName, id, request }) => ({
      url: EndpointUrlBuilder.createModelContactRecord(modelName, id),
      method: 'POST',
      data: request,
    }),
    transformResponse: (response: ICreateOrderContactRecordResponse): IActionRecord | IErrorResponse =>
      isErrorResponse(response) ? response : response.contactRecord,
  },
);
