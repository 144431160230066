import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { IAllocationSummary } from '@features/allocations/allocation.interface';
import { TransportableModel } from '@models/transportable.type';
import { ApiModel } from './form-config';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { validateAllocationSummaries } from './spec/validate-allocation-summaries.spec';

type FilterAllocationsQueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type FilterAllocationsResponse = IPaginable<IAllocationSummary>;
export const buildGetAllocations = appQueryFactory<FilterAllocationsQueryParams, FilterAllocationsResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getAllocations(),
    method: 'GET',
    params,
  }),
  transformResponse(response: FilterAllocationsResponse) {
    validateAllocationSummaries(response, EndpointUrlBuilder.getAllocations());
    validatePagination(response, EndpointUrlBuilder.getAllocations());
    return response;
  },
});
