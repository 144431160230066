import { appQueryFactory } from '@api/api-slice';
import { ISODateString } from '@utils/dates/iso-string.type';
import { validateDeliveryCalendar } from './spec/validate-delivery-calendar.spec';
import { EndpointUrlBuilder } from '@api/endpoints';

export interface IOrderDeliverySummary {
  id: number;
  customerId: number;
  orderTypeId: number;
  pickUp: ISODateString;
  customerFullName: string;
  organisationId: number | null;
}

export interface IOrderGroupByType {
  orderTypeId: number;
  orders: IOrderDeliverySummary[];
}

export interface IOrderGroupByOrganisation {
  organisationId: number | null;
  groupedByType: IOrderGroupByType[];
}

export interface IDeliveryCalendar {
  [pickUpDate: string]: {
    count: number;
    groupedByOrganisation: IOrderGroupByOrganisation[];
  };
}

type ISODateRange = [ISODateString, ISODateString];

export const buildGetDeliveryCalendar = appQueryFactory<ISODateRange, IDeliveryCalendar>({
  query: ([startDate, endDate]) => ({
    url: EndpointUrlBuilder.deliverySchedule(),
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  }),
  transformResponse: (response: IDeliveryCalendar) => {
    validateDeliveryCalendar(response, EndpointUrlBuilder.deliverySchedule());
    return response;
  },
});
