import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IMicrosoftAuthorizationState } from './microsoft.slice';
import { EndpointUrlBuilder } from '@api/endpoints';

interface IMicrosoftAuthorizationResponse {
  uri: string;
  isLoggedIn: boolean;
  organisationName: string;
}

export const buildGetMicrosoftLink = appQueryFactory<string, IMicrosoftAuthorizationState>({
  query: (userId) => ({
    url: EndpointUrlBuilder.getMicrosoftLink(userId),
    method: 'GET',
  }),
  transformResponse: (response: IMicrosoftAuthorizationResponse) => ({
    isLoggedIn: response.isLoggedIn,
    authorizationUri: response.uri,
    organisationName: response.organisationName,
  }),
});

export const buildRequestMicrosoftLogout = appMutationFactory<string, void>({
  query: (userId) => ({
    url: EndpointUrlBuilder.requestMicrosoftLogout(userId),
    method: 'POST',
  }),
});
