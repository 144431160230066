import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { ISODateString } from '@utils/dates/iso-string.type';
import { IOrderTypeStatusSummary } from './order-type-status-summary.interface';
import { IOrderTypeSummary } from './order-type-summary.interface';
import { validateOrderTypeSummary } from './spec/validate-order-type-summary.spec';
import { validateOrderTypeStatusSummary } from './spec/validate-order-type-status-summary.spec';

type OrderTypeSummariesRequest = [ISODateString, ISODateString];
type OrderTypeSummariesResponse = IOrderTypeSummary[];
export const buildGetOrderTypeSummaries = appQueryFactory<OrderTypeSummariesRequest, OrderTypeSummariesResponse>({
  query: ([startDate, endDate]) => ({
    url: EndpointUrlBuilder.getOrderTypeSummaries(),
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  }),
  transformResponse(response: OrderTypeSummariesResponse) {
    validateOrderTypeSummary(response, EndpointUrlBuilder.getOrderTypeSummaries());
    return response;
  },
});

type OrderTypeStatusSummariesResponse = IOrderTypeStatusSummary[];
export const buildGetOrderTypeStatusSummaries = appQueryFactory<void, OrderTypeStatusSummariesResponse>({
  query: () => ({
    url: EndpointUrlBuilder.getOrderTypeStatusSummaries(),
    method: 'GET',
  }),
  transformResponse(response: OrderTypeStatusSummariesResponse) {
    validateOrderTypeStatusSummary(response, EndpointUrlBuilder.getOrderTypeStatusSummaries());
    return response;
  },
});
