import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { ISessionSummary } from '@features/sessions/session.interface';
import { TransportableModel } from '@models/transportable.type';
import { ApiModel } from './form-config';
import { validateSessionSummaries } from './spec/validate-session-summaries.spec';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { EndpointUrlBuilder } from '@api/endpoints';

export type FilterSessionsQueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type FilterSessionsResponse = IPaginable<ISessionSummary>;
export const buildGetSessions = appQueryFactory<FilterSessionsQueryParams, FilterSessionsResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getSessionSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: FilterSessionsResponse) {
    validateSessionSummaries(response, EndpointUrlBuilder.getSessionSummaries());
    validatePagination(response, EndpointUrlBuilder.getSessionSummaries());
    return response;
  },
});
