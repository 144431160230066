import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { IAppointment } from '../appointment.interface';

type SubmitNoShowAppointmentRequest = {
  orderId: number;
  appointmentId: number | null;
};

export const buildSubmitNoShow = appMutationFactory<SubmitNoShowAppointmentRequest, IAppointment | IErrorResponse>({
  query: ({ appointmentId, orderId }) => ({
    url: EndpointUrlBuilder.submitAppointmentNoShow(orderId, appointmentId),
    method: 'PATCH',
  }),
  transformResponse(response: { needsAssessmentAppointment: IAppointment } | IErrorResponse) {
    return isErrorResponse(response) ? response : response.needsAssessmentAppointment;
  },
});
