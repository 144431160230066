import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ApiModel } from './form-config';
import { IReport } from '../report.interface';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { EndpointUrlBuilder } from '@api/endpoints';

type SaveReportRequest = {
  orderId: number;
  reportId: number | null;
  payload: Partial<ApiModel>;
};

type ServerResponse = { needsAssessmentReport: IReport } | IErrorResponse;

export const buildCreateReport = appMutationFactory<SaveReportRequest, IReport | IErrorResponse>({
  query: ({ payload, orderId }) => ({
    url: EndpointUrlBuilder.createReport(orderId),
    method: 'POST',
    data: { needs_assessment_report: modelToSnakeCase(payload) },
  }),
  transformResponse,
});

export const buildSaveReport = appMutationFactory<SaveReportRequest, IReport | IErrorResponse>({
  query: ({ payload, orderId, reportId }) => ({
    url: EndpointUrlBuilder.saveReport(orderId, reportId),
    method: 'PATCH',
    data: { needs_assessment_report: modelToSnakeCase(payload) },
  }),
  transformResponse,
});

function transformResponse(response: ServerResponse): IReport | IErrorResponse {
  return isErrorResponse(response) ? response : response.needsAssessmentReport;
}
