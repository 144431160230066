import { appQueryFactory } from '@api/api-slice';
import { ICustomer } from '@features/customer/customer.interface';
import { validateCustomer } from './spec/validate-customer.spec';
import { EndpointUrlBuilder } from '@api/endpoints';

export interface ICustomerResponse {
  student: ICustomer;
}

export const buildGetCustomer = appQueryFactory<number, ICustomer>({
  query: (customerId) => ({
    url: EndpointUrlBuilder.getCustomer(customerId),
    method: 'GET',
  }),
  transformResponse: (response: ICustomerResponse, meta, customerId) => {
    validateCustomer(response.student, EndpointUrlBuilder.getCustomer(customerId));
    return response.student;
  },
});
