import { appQueryFactory } from '@api/api-slice';
import { IAllocation } from './allocation.interface';
import { validateAllocations } from './spec/validate-allocations.spec';
import { EndpointUrlBuilder } from '@api/endpoints';

type Response = {
  nmhAllocations: IAllocation[];
};

export const buildGetOrderAllocations = appQueryFactory<number, IAllocation[]>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.getOrderAllocations(orderId),
    method: 'GET',
  }),
  transformResponse(response: Response, meta, orderId) {
    validateAllocations(response.nmhAllocations, EndpointUrlBuilder.getOrderAllocations(orderId));
    return response.nmhAllocations;
  },
});
