import { useCallback } from 'react';
import { useSetImmyBotInstallationLinkMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { OrdersActions } from '@features/order/orders.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  handleGetImmyBotInstallationLink: () => Promise<void>;
} & RequestStatusFlags;

export function useSetImmyBotInstallationLink(orderId: number, customerId: number): HookResult {
  const [mutation, flags] = useSetImmyBotInstallationLinkMutation();
  const dispatch = useAppDispatch();

  const handleGetImmyBotInstallationLink = useCallback(async (): Promise<void> => {
    const result = await mutation({ orderId, customerId }).unwrap();
    // showing message handled at api layer
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(OrdersActions.updateOrder(result));
  }, [customerId, dispatch, mutation, orderId]);

  return {
    ...flags,
    handleGetImmyBotInstallationLink,
  };
}
