import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { TransportableModel } from '@models/transportable.type';
import { IAppointment } from './appointment.interface';
import { validateAppointments } from './spec/validate-appointments.spec';

export type FilterAppointmentsQueryParams = TransportableModel<{ orderId: number }>;
type ServerResponse =
  | {
      needsAssessmentAppointments: IAppointment[];
    }
  | IErrorResponse;
export type FilterAppointmentsResponse = IAppointment[] | IErrorResponse;
export const buildGetAppointments = appQueryFactory<FilterAppointmentsQueryParams, FilterAppointmentsResponse>({
  query: ({ orderId }) => ({
    url: EndpointUrlBuilder.getOrderAppointments(orderId),
    method: 'GET',
  }),
  transformResponse(response: ServerResponse, meta, request) {
    if (isErrorResponse(response)) {
      return response;
    }
    const { orderId } = request;
    validateAppointments(response.needsAssessmentAppointments, EndpointUrlBuilder.getOrderAppointments(orderId));
    return response.needsAssessmentAppointments;
  },
});
