import { useCallback } from 'react';
import { useConnectToImmyBotMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { CustomersActions, selectCustomerState } from '@features/customer/customer.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = {
  handleSubmitConnectToImmyBot: () => Promise<void>;
  isConnected: boolean;
} & RequestStatusFlags;

export function useConnectToImmyBot(orderId: number, customerId: number): HookResult {
  const [mutation, flags] = useConnectToImmyBotMutation();
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => selectCustomerState(state));

  const handleSubmitConnectToImmyBot = useCallback(async (): Promise<void> => {
    const result = await mutation({ orderId, customerId }).unwrap();
    // showing message handled at api layer
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(CustomersActions.setExternalImmyBotId(result));
  }, [customerId, dispatch, mutation, orderId]);

  return {
    ...flags,
    handleSubmitConnectToImmyBot,
    isConnected: customer?.externalImmyBotId !== null,
  };
}
