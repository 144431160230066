import { useEffect } from 'react';
import { useAppDispatch } from '@store/use-app-dispatch';
import { SessionInvoiceSummaryActions, selectAllSessionSummaries } from '../session-invoice-summary.slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetSessionInvoiceSummariesQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { ISessionInvoiceSummary } from '@features/bulk-invoicing/session/session-invoice-summary.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { sanitize, serialize } from './form-config';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & IPaginable<ISessionInvoiceSummary>;

export function useFilteredSessionInvoiceSummaries(
  urlSearchParams: URLSearchParams,
  count: number,
  skip: number,
): HookResult {
  const backendLabels = useAppBackendLabels([
    'organisationOptions',
    'tutorOptions',
    'qualificationOptions',
    'statusOptions',
  ]);
  const dispatch = useAppDispatch();

  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  const queryResult = useGetSessionInvoiceSummariesQuery({ ...params, count, skip, isFinance: true });
  const { entries, ...rest } = getPaginationResult(queryResult);

  useEffect(() => {
    if (rest.isSuccess) {
      dispatch(SessionInvoiceSummaryActions.setSessions(entries));
    }
  }, [entries, dispatch, rest.isSuccess]);

  const apps = useAppSelector((state) => selectAllSessionSummaries(state));

  return { entries: apps, ...rest };
}
