import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IOrderSummary } from '../order-summary.interface';
import { ApiModel } from './form-config';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { validateFilterOrderSummary } from '../filter-order-summaries-form/spec/validate-order-summaries.spec';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type OrderSummaryResponse = IPaginable<IOrderSummary>;
export const buildGetAssessmentOrderSummaries = appQueryFactory<QueryParams, OrderSummaryResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.astOrderSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: OrderSummaryResponse) {
    validateFilterOrderSummary(response, EndpointUrlBuilder.astOrderSummaries());
    validatePagination(response, EndpointUrlBuilder.astOrderSummaries());
    return response;
  },
});
