import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IOrder } from '@features/order/order.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { validateOrder } from './spec/validate-order.spec';

type SaveOrderRequest = {
  id: number | null;
  payload: Partial<IOrder>;
};

type SaveOrderPayload = {
  order: Partial<ToSnakeCase<IOrder>>;
};

type SaveOrderResponse = {
  order: IOrder;
};

export const buildCreateOrder = appMutationFactory<SaveOrderRequest, IOrder | IErrorResponse>({
  query: ({ payload }) => ({
    url: EndpointUrlBuilder.createOrder(),
    method: 'POST',
    data: createOrderPayload(payload),
  }),
  transformResponse,
});

export const buildSaveOrder = appMutationFactory<SaveOrderRequest, IOrder | IErrorResponse>({
  query: ({ id, payload }) => {
    return {
      url: EndpointUrlBuilder.saveOrder(id),
      method: 'PATCH',
      data: createOrderPayload(payload),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  },
  transformResponse,
});

function createOrderPayload(order: Partial<IOrder>): SaveOrderPayload {
  return {
    order: modelToSnakeCase(order),
  };
}

function transformResponse(response: SaveOrderResponse | IErrorResponse): IOrder | IErrorResponse {
  return isErrorResponse(response) ? response : response.order;
}

type GetOrderRequest = {
  orderId: number;
  customerId: number;
};
export const buildGetOrder = appQueryFactory<GetOrderRequest, IOrder>({
  query: ({ orderId, customerId }) => {
    return {
      url: EndpointUrlBuilder.getOrder(orderId),
      method: 'GET',
      params: {
        student_id: customerId,
      },
    };
  },
  transformResponse(response: SaveOrderResponse, meta, request) {
    const { orderId } = request;
    validateOrder(response.order, EndpointUrlBuilder.getOrder(orderId));
    return response.order;
  },
});
