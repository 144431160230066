import { orderBy } from 'lodash';
import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { IAssessorOption } from '@features/backend-label/user-label.interface';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { IAppointment } from '../appointment.interface';
import { ApiModel } from './form-config';

type SaveAppointmentRequest = {
  orderId: number;
  appointmentId: number | null;
  payload: Partial<ApiModel>;
};

type ServerResponse = { needsAssessmentAppointment: IAppointment } | IErrorResponse;

export const buildCreateAppointment = appMutationFactory<SaveAppointmentRequest, IAppointment | IErrorResponse>({
  query: ({ payload, orderId }) => ({
    url: EndpointUrlBuilder.createOrderAppointment(orderId),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
  transformResponse,
});

export const buildSaveAppointment = appMutationFactory<SaveAppointmentRequest, IAppointment | IErrorResponse>({
  query: ({ payload, orderId, appointmentId }) => ({
    url: EndpointUrlBuilder.saveOrderAppointment(orderId, appointmentId),
    method: 'PATCH',
    data: modelToSnakeCase(payload),
  }),
  transformResponse,
});

export const buildGetFilteredAssessorsForOrderId = appQueryFactory<number, IAssessorOption[] | IErrorResponse>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.getFilteredAssessorsByOrder(orderId),
    method: 'GET',
  }),
  // TODO - Remove when the backend starts sending the sorted array
  transformResponse(response: IAssessorOption[]) {
    return orderBy(response, [(assessor): string => assessor.label.toLowerCase()], ['asc']);
  },
});

function transformResponse(response: ServerResponse): IAppointment | IErrorResponse {
  return isErrorResponse(response) ? response : response.needsAssessmentAppointment;
}
