import { appMutationFactory } from '@api/api-slice';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { ApiModel } from './form-config';
import { IAnnualSupportCharge } from '../annual-support-charge-list/annual-support-charge.interface';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { EndpointUrlBuilder } from '@api/endpoints';

type SaveAnnualSupportChargeRequest = {
  orderId: number;
  annualSupportChargeId: number | null;
  payload: Partial<ApiModel>;
};

type ServerResponse = { annualSupportCharge: IAnnualSupportCharge } | IErrorResponse;
type Response = IAnnualSupportCharge | IErrorResponse;

export const buildCreateAnnualSupportChargeManually = appMutationFactory<SaveAnnualSupportChargeRequest, Response>({
  query: ({ orderId, payload }) => ({
    url: EndpointUrlBuilder.createAnnualSupportCharge(orderId),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
  transformResponse,
});

export const buildSaveAnnualSupportCharge = appMutationFactory<SaveAnnualSupportChargeRequest, Response>({
  query: ({ payload, orderId, annualSupportChargeId }) => ({
    url: EndpointUrlBuilder.saveAnnualSupportCharge(orderId, annualSupportChargeId),
    method: 'PATCH',
    data: modelToSnakeCase(payload),
  }),
  transformResponse,
});

function transformResponse(response: ServerResponse): Response {
  return isErrorResponse(response) ? response : response.annualSupportCharge;
}
