import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ISession } from '@features/sessions/session.interface';
import { ISODateString } from '@utils/dates/iso-string.type';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { ApiModel } from './form-config';

interface ISessionPayload {
  nmh_session: {
    nmh_allocation_id: number;
    id?: number;
    start_moment?: ISODateString;
    duration?: number;
    tutor_invoice?: string | null;
    funder_invoice?: string | null;
    is_cancelled?: boolean;
    electronic_invoice?: string;
  };
}

interface ISessionResponse {
  nmhSession: ISession;
}

function createSessionPayload(payload: Partial<ApiModel>, allocationId: number): ISessionPayload {
  return {
    nmh_session: {
      nmh_allocation_id: allocationId,
      ...modelToSnakeCase(payload),
    },
  };
}

type SaveSessionRequest = {
  allocationId: number;
  payload: Partial<ApiModel> & { id?: number | null };
};

export const buildCreateSession = appMutationFactory<SaveSessionRequest, ISession | IErrorResponse>({
  query: ({ payload, allocationId }) => ({
    url: EndpointUrlBuilder.createSession(),
    method: 'POST',
    data: createSessionPayload(payload, allocationId),
  }),
  transformResponse: (response: ISessionResponse | IErrorResponse) =>
    isErrorResponse(response) ? response : response.nmhSession,
});

export const buildSaveSession = appMutationFactory<SaveSessionRequest, ISession | IErrorResponse>({
  query: ({ payload, allocationId }) => ({
    url: EndpointUrlBuilder.saveSession(payload.id),
    method: 'PATCH',
    data: createSessionPayload(payload, allocationId),
  }),
  transformResponse: (response: ISessionResponse | IErrorResponse) =>
    isErrorResponse(response) ? response : response.nmhSession,
});
