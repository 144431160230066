import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';

export interface ISignSessionRequest {
  nmh_session: {
    timesheet_file: File;
  };
}

export const buildUploadTimesheet = appMutationFactory<{ sessionId: number; file: File }, ISignSessionRequest>({
  query: ({ sessionId, file }) => ({
    url: EndpointUrlBuilder.uploadTimesheet(),
    method: 'PATCH',
    params: {
      // TODO какого хрена это передается в квери, когда есть body?
      id: sessionId,
    },
    // TODO вычистить snakifyKeys после исправления бекенда
    data: { nmh_session: { timesheet_file: file } },
  }),
});

interface IRegenerateTimesheetResponse {
  newDocumentPath: string;
}

export const buildRegenerateTimesheet = appMutationFactory<number, string>({
  query: (sessionId) => ({
    url: EndpointUrlBuilder.regenerateTimesheet(sessionId),
    method: 'PATCH',
  }),
  transformResponse(response: IRegenerateTimesheetResponse) {
    return response.newDocumentPath;
  },
});
