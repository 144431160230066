import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { IAppointmentInvoiceSummary } from './appointment-invoice-summary.interface';
import { BulkUpdateResponse } from '../bulk-update-response.type';
import { BulkUpdateRequest } from '../bulk-update-request.type';

type Response = BulkUpdateResponse<IAppointmentInvoiceSummary> | void;

type SupplierRequest = BulkUpdateRequest & { supplierInvoice: string };
export const buildBulkAddAppointmentSupplierInvoice = appMutationFactory<SupplierRequest, Response>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkAddAppointmentSupplierInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkGenerateAppointmentXeroInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkGenerateAppointmentXeroInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkSendAppointmentInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkSendAppointmentInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});
