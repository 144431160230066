import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { ISession } from './session.interface';
import { validateSessions } from './spec/validate-sessions.spec';

type Response = {
  nmhSessions: ISession[];
};

export const buildGetAllocationSessions = appQueryFactory<number, ISession[]>({
  query: (allocationId) => ({
    url: EndpointUrlBuilder.getAllocationSessions(allocationId),
    method: 'GET',
  }),
  transformResponse(response: Response, meta, allocationId) {
    validateSessions(response.nmhSessions, EndpointUrlBuilder.getAllocationSessions(allocationId));
    return response.nmhSessions;
  },
});
