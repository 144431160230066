import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { TransportableModel } from '@models/transportable.type';
import { ISessionInvoiceSummary } from '../session-invoice-summary.interface';
import { validateFilterSessionInvoiceSummary } from './spec/validate-session-invoice-summaries.spec';
import { ApiModel } from './form-config';
import { FinanceParams } from '@features/bulk-invoicing/finance-params.type';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs & FinanceParams>;
export type SessionInvoiceSummariesResponse = IPaginable<ISessionInvoiceSummary>;
export const buildGetSessionInvoiceSummaries = appQueryFactory<QueryParams, SessionInvoiceSummariesResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getSessionInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: SessionInvoiceSummariesResponse) {
    validateFilterSessionInvoiceSummary(response, EndpointUrlBuilder.getSessionInvoiceSummaries());
    validatePagination(response, EndpointUrlBuilder.getSessionInvoiceSummaries());
    return response;
  },
});
