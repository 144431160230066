import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { IElectronicInvoiceLog } from '@features/electronic-invoice/electronic-invoice-log.interface';

export const buildRefreshAppointmentElectronicInvoice = appMutationFactory<number, string | IErrorResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.refreshAppointmentElectronicInvoice(id),
    method: 'POST',
  }),
});

type SendElectronicInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildSendAppointmentElectronicInvoice = appMutationFactory<number, SendElectronicInvoiceResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.sendAppointmentElectronicInvoice(id),
    method: 'POST',
  }),
});

type GenerateXeroInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildGenerateAppointmentElectronicInvoice = appMutationFactory<number, GenerateXeroInvoiceResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.generateAppointmentElectronicInvoice(id),
    method: 'POST',
  }),
});
