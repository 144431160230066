import React from 'react';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { CopyToClipboard } from '@components/CopyToClipboard';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { useConnectToImmyBot } from './use-connect-to-immy-bot';
import { useSetImmyBotInstallationLink } from './use-set-immy-bot-installation-link';

type Props = {
  order: IOrderTechnical;
};

export const ImmyBotLink: React.FC<Props> = ({ order }) => {
  const {
    handleSubmitConnectToImmyBot,
    isLoading: isConnecting,
    isConnected,
  } = useConnectToImmyBot(order.id, order.customerId);
  const { handleGetImmyBotInstallationLink, isLoading: isGetting } = useSetImmyBotInstallationLink(
    order.id,
    order.customerId,
  );
  const link = order.immyBotInstallationLink;

  if (link) {
    return (
      <CopyToClipboard
        textToCopy={link}
        label="Copy Immy Bot Installation Link"
        variant="outlined"
        fullWidth
        size="medium"
      />
    );
  }

  if (isConnected) {
    return (
      <AppLoadingButton isLoading={isGetting} onClick={handleGetImmyBotInstallationLink} variant="outlined" fullWidth>
        Get Immy Bot Installation Link
      </AppLoadingButton>
    );
  }

  return (
    <AppLoadingButton isLoading={isConnecting} onClick={handleSubmitConnectToImmyBot} variant="outlined" fullWidth>
      Connect to Immy Bot
    </AppLoadingButton>
  );
};
