import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { AssessmentCentreSummary, IAssessmentCentre } from './assessment-centre.interface';
import { validateAssessmentCentres } from './filter-assessment-centres-form/spec/validate-assessment-centres';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { validateAssessmentCentreDetail } from './spec/validate-assessment-centre-detail';
import { EndpointUrlBuilder } from '@api/endpoints';

export type AssessmentCentreQueryParams = TransportableModel<PaginationArgs>;
export type AssessmentCentreSummaryResponse = IPaginable<AssessmentCentreSummary>;

export const buildGetAssessmentCentresSummaries = appQueryFactory<
  AssessmentCentreQueryParams,
  AssessmentCentreSummaryResponse
>({
  query: (params) => ({
    url: EndpointUrlBuilder.getAssessmentCentresSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: AssessmentCentreSummaryResponse) {
    validateAssessmentCentres(response, EndpointUrlBuilder.getAssessmentCentresSummaries());
    validatePagination(response, EndpointUrlBuilder.getAssessmentCentresSummaries());
    return response;
  },
});

export interface IAssessmentCentreResponse {
  assessmentCentre: IAssessmentCentre;
}

export const buildGetAssessmentCentre = appQueryFactory<number, IAssessmentCentreResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.getAssessmentCentre(id),
    method: 'GET',
  }),
  transformResponse(response: IAssessmentCentreResponse, meta, id) {
    validateAssessmentCentreDetail(response, EndpointUrlBuilder.getAssessmentCentre(id));
    return response;
  },
});
