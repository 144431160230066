import { appQueryFactory } from '@api/api-slice';
import { AppBackendLabelKey, AppBackendLabelsBase } from './backend-label.type';
import { EndpointUrlBuilder } from '@api/endpoints';

export const buildGetLabels = appQueryFactory<Array<AppBackendLabelKey>, Partial<AppBackendLabelsBase>>({
  query: (names) => ({
    url: EndpointUrlBuilder.getLabels(),
    method: 'GET',
    params: {
      query: names.join(','),
    },
  }),
});
