import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { ISalesOrderLine } from '../sales-order-line.interface';

type Response = {
  salesOrderLines: ISalesOrderLine[];
};

export const buildUpdateSalesOrderLines = appMutationFactory<number, Response | IErrorResponse>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.updateSalesOrderLines(orderId),
    method: 'PATCH',
  }),
});
