import { appQueryFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { cureWrongISODate } from './cure-wrong-iso-date';
import { ISlot } from './slot.interface';
import { ISODateTimeString } from '@utils/dates/iso-string.type';
import { EndpointUrlBuilder } from '@api/endpoints';

type AppointmentZohoSlotsQuery = {
  orderId: number;
  qualification?: never;
  tutorId?: never;
};

type NmhZohoSlotsQuery = {
  orderId?: never;
  qualification: string | number;
  tutorId?: number | null;
};

export type ZohoSlotsQuery = AppointmentZohoSlotsQuery | NmhZohoSlotsQuery;

type ZohoSlotsQueryWithDateRange = ZohoSlotsQuery & { from: string; to: string };

type ZohoSlotsResponse = {
  freeSlots: ISlot[];
  offeredDate: ISODateTimeString;
};

export const buildGetZohoFreeSlots = appQueryFactory<ZohoSlotsQueryWithDateRange, ZohoSlotsResponse | IErrorResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getZohoFreeSlots(),
    method: 'GET',
    params: createParams(params),
  }),
  transformResponse,
});

function createParams({
  orderId,
  from,
  to,
  qualification,
  tutorId,
}: ZohoSlotsQueryWithDateRange): Record<string, string> {
  const params: Record<string, string> = {
    from,
    to,
  };
  if (orderId) {
    params.orderId = orderId.toString();
  } else if (tutorId) {
    params.tutorId = tutorId.toString();
  } else if (qualification) {
    params.qualification = qualification.toString();
  }
  return params;
}

function transformResponse(response: ZohoSlotsResponse | IErrorResponse): ZohoSlotsResponse | IErrorResponse {
  if (isErrorResponse(response)) {
    return response;
  }
  return {
    offeredDate: response.offeredDate,
    // TODO remove this shit when backend return proper ISO dates
    freeSlots: response.freeSlots.map(({ start, end }) => ({
      start: cureWrongISODate(start),
      end: cureWrongISODate(end),
    })),
  };
}
