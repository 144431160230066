import { appQueryFactory } from '@api/api-slice';
import { validateActionRecords } from '@features/action-records/spec/validate-action-record.spec';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { RecordModelNameType } from './record-model-name.type';
import { TransportableModel } from '@models/transportable.type';
import { EndpointUrlBuilder } from '@api/endpoints';

export type GetModelProgressRecordsQueryParams = TransportableModel<
  { modelName: RecordModelNameType; id: number } & PaginationArgs
>;

export const buildGetModelProgressRecords = appQueryFactory<
  GetModelProgressRecordsQueryParams,
  IPaginable<IActionRecord>
>({
  query: ({ modelName, id }) => ({
    url: EndpointUrlBuilder.getModelProgressRecord(modelName, id),
    method: 'GET',
  }),
  transformResponse(response: IPaginable<IActionRecord>, meta, request) {
    const { modelName, id } = request;
    validateActionRecords(response.entries, EndpointUrlBuilder.getModelProgressRecord(modelName, id));
    validatePagination(response, EndpointUrlBuilder.getModelProgressRecord(modelName, id));
    return response;
  },
});
