import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { IProductWithCondition } from './product.interface';
import { ApiModel } from './product-details/form-config';
import { validateProductDetail } from './spec/validate-product-detail.spec';

export interface IProductWithConditionResponse {
  product: IProductWithCondition;
}

type ProductPayload = {
  product_update: ToSnakeCase<Partial<ApiModel>>;
};

function createProductPayload(payload: Partial<ApiModel>): ProductPayload {
  return {
    product_update: {
      ...modelToSnakeCase(payload),
    },
  };
}

export const buildGetProduct = appQueryFactory<number, IProductWithConditionResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.getProduct(id),
    method: 'GET',
  }),
  transformResponse(response: IProductWithConditionResponse, meta, id) {
    validateProductDetail(response, EndpointUrlBuilder.getProduct(id));
    return response;
  },
});

type SaveProductRequest = {
  id: number;
  payload: Partial<ApiModel>;
};

type SaveProductResponse = {
  product: IProductWithCondition;
};
export const buildSaveProduct = appMutationFactory<SaveProductRequest, SaveProductResponse | IErrorResponse>({
  query: ({ payload, id }) => ({
    url: EndpointUrlBuilder.saveProduct(id),
    method: 'PATCH',
    data: createProductPayload(payload),
  }),
});
