import { appMutationFactory } from '@api/api-slice';
import { AnonymousEndpoints } from '@api/anonymous-endpoints';

export interface IVerifyEmailRequest {
  account: { email: string };
}
export interface IConfirmRequest {
  account: { reset_password_token: string; password: string; password_confirmation: string };
}
export interface IResetResponseError {
  error: string;
}

type IVerifyResponse = object | IResetResponseError;

export const buildVerifyEmail = appMutationFactory<IVerifyEmailRequest, IVerifyResponse>({
  query: (data) => ({
    url: AnonymousEndpoints.resetPassword,
    method: 'POST',
    data,
  }),
});

export const buildConfirmPassword = appMutationFactory<IConfirmRequest, IResetResponseError>({
  query: (data) => ({
    url: AnonymousEndpoints.resetPassword,
    method: 'PATCH',
    data,
  }),
});
