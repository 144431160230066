import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { Update } from '@reduxjs/toolkit';
import { modelToSnakeCase } from '@utils/model-to-snake-case';

export type ImmyBotPayload = {
  orderId: number;
  customerId: number;
};

type ConnectToImmyBotResponse = {
  externalImmyBotId: number;
};

export const buildConnectToImmyBot = appMutationFactory<ImmyBotPayload, ConnectToImmyBotResponse | IErrorResponse>({
  query: ({ orderId, customerId }) => ({
    url: EndpointUrlBuilder.connectToImmyBot(customerId),
    method: 'POST',
    data: modelToSnakeCase({ orderId }),
  }),
});

type SetImmyBotInstallationLinkResponse = Update<IOrderTechnical> | IErrorResponse;

export const buildSetImmyBotInstallationLink = appMutationFactory<ImmyBotPayload, SetImmyBotInstallationLinkResponse>({
  query: ({ orderId, customerId }) => ({
    url: EndpointUrlBuilder.setImmyBotInstallationLink(orderId),
    method: 'POST',
    params: {
      student_id: customerId,
    },
  }),
});
